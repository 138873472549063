import React from 'react';

export function TermsPage() {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Conditions Générales d'Utilisation</h1>
        
        <div className="space-y-8 text-gray-600">
          <section>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Objet</h2>
            <p>
              Les présentes CGU régissent l'utilisation du site et les services proposés
              par IMHOFF STEPHANIE. En utilisant notre site, vous acceptez ces conditions dans leur intégralité.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Services proposés</h2>
            <p>
              IMHOFF STEPHANIE propose des solutions d'épargne et d'investissement. Les services sont
              réservés aux personnes majeures ayant la capacité juridique de contracter.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. Responsabilités</h2>
            <p>
              IMHOFF STEPHANIE s'efforce d'assurer l'exactitude et la mise à jour des informations diffusées
              sur son site. Toutefois, nous ne pouvons garantir l'exactitude, la précision ou l'exhaustivité
              des informations mises à disposition.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Investissements et risques</h2>
            <p>
              Tout investissement comporte des risques. Les performances passées ne préjugent pas des
              performances futures. Il est recommandé de diversifier ses investissements et de ne pas
              investir au-delà de ses capacités financières.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Modification des CGU</h2>
            <p>
              IMHOFF STEPHANIE se réserve le droit de modifier les présentes CGU à tout moment. Les utilisateurs
              seront informés des modifications par une mention sur le site.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Droit applicable</h2>
            <p>
              Les présentes CGU sont soumises au droit français. Tout litige relatif à leur interprétation
              et/ou à leur exécution relève des tribunaux français.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}