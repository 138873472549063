import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { LegalPage } from './pages/LegalPage';
import { PrivacyPage } from './pages/PrivacyPage';
import { TermsPage } from './pages/TermsPage';
import { ThankYouPage } from './pages/ThankYouPage';
import { CreditimoPage } from './pages/CreditimoPage';
import { CreditimoThankYouPage } from './pages/CreditimoThankYouPage';
import { Footer } from './components/Footer';
import { CookieConsent } from './components/CookieConsent';

export default function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/mentions-legales" element={<LegalPage />} />
            <Route path="/confidentialite" element={<PrivacyPage />} />
            <Route path="/conditions-generales" element={<TermsPage />} />
            <Route path="/merci" element={<ThankYouPage />} />
            <Route path="/creditimo" element={<CreditimoPage />} />
            <Route path="/creditimo/merci" element={<CreditimoThankYouPage />} />
          </Routes>
        </main>
        <Footer />
        <CookieConsent />
      </div>
    </Router>
  );
}
