import React from 'react';

interface FeatureCardProps {
  title: string;
  description: string;
  imageSrc: string;
}

export function FeatureCard({ title, description, imageSrc }: FeatureCardProps) {
  return (
    <div className="flex flex-col items-center text-center">
      <img 
        src={imageSrc} 
        alt={title}
        className="w-full h-64 object-cover rounded-lg shadow-md mb-4"
      />
      <h3 className="text-xl font-bold text-gray-900 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
}