import React from 'react';

export function LegalNotice() {
  return (
    <div className="text-xs text-gray-500 leading-relaxed">
      <p className="mb-2">
        *Les performances passées ne préjugent pas des performances futures. Tout investissement comporte des risques.
      </p>
      <p>
        © 2024 IMHOFF STEPHANIE. Tous droits réservés. SIRET : 851 062 646 00013.
        Les services financiers proposés sont soumis à la réglementation en vigueur. 
        Document à caractère non contractuel. Consultez nos conditions générales pour plus d'informations.
      </p>
    </div>
  );
}