import React from 'react';

export function LegalPage() {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Mentions Légales</h1>
        
        <div className="space-y-8 text-gray-600">
          <section>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Informations légales</h2>
            <p>
              Le site imhoff-stephanie.com est édité par IMHOFF STEPHANIE, entreprise individuelle
              immatriculée au RCS sous le numéro 851 062 646.
            </p>
            <p className="mt-2">
              Siège social : 3 RUE DES CHATAIGNIERS, 69960 CORBAS
            </p>
            <p className="mt-2">
              Date de création : 12/05/2019
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Directeur de la publication</h2>
            <p>
              Le directeur de la publication est Mme STEPHANIE IMHOFF, en sa qualité de dirigeante.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. Hébergement</h2>
            <p>
              Le site est hébergé par la société Netlify, Inc.
            </p>
            <p className="mt-2">
              Siège social : 44 Montgomery Street, Suite 300, San Francisco, California 94104.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Propriété intellectuelle</h2>
            <p>
              L'ensemble du contenu du site (textes, images, vidéos, etc.) est protégé par le droit d'auteur
              et est la propriété exclusive de STEPHANIE IMHOFF ou de ses partenaires.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Protection des données personnelles</h2>
            <p>
              Conformément à la loi "Informatique et Libertés" du 6 janvier 1978 modifiée et au Règlement Général sur la
              Protection des Données (RGPD), vous disposez d'un droit d'accès, de rectification, de suppression et
              d'opposition aux données personnelles vous concernant.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Activité</h2>
            <p>
              Activité principale : Agences immobilières
            </p>
            <p className="mt-2">
              SIRET : 851 062 646 00013
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}