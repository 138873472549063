import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';

export function ThankYouPage() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-white rounded-2xl shadow-xl p-8 text-center">
        <div className="flex justify-center mb-6">
          <CheckCircle className="w-20 h-20 text-green-500" />
        </div>
        
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          Merci de votre inscription
        </h1>
        
        <p className="text-gray-600 mb-8 text-lg">
          Un conseiller vous contactera sous 24 heures
        </p>

        <Link 
          to="/"
          className="inline-block bg-green-600 text-white rounded-lg py-3 px-8 hover:bg-green-700 transition-colors font-semibold"
        >
          Retour à l'accueil
        </Link>
      </div>
    </div>
  );
}