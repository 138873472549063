import React from 'react';
import { Percent, Clock, Search, BadgeCheck, PiggyBank, FileCheck } from 'lucide-react';

interface FeatureItemProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  color: string;
}

function FeatureItem({ icon, title, description, color }: FeatureItemProps) {
  return (
    <div className={`flex items-start space-x-4 bg-white rounded-xl p-6 shadow-lg border-l-4 ${color}`}>
      <div className="flex-shrink-0">
        {icon}
      </div>
      <div>
        <h3 className="font-bold text-gray-900 text-lg">{title}</h3>
        <p className="text-gray-600 mt-1">{description}</p>
      </div>
    </div>
  );
}

export function CreditimoFeatureList() {
  const features = [
    {
      icon: <Percent className="w-8 h-8 text-blue-600" />,
      title: "Meilleurs taux négociés",
      description: "Accédez aux meilleures conditions du marché grâce à notre réseau de partenaires",
      color: "border-blue-600"
    },
    {
      icon: <PiggyBank className="w-8 h-8 text-green-600" />,
      title: "Solutions adaptées",
      description: "Des solutions de financement adaptées à votre situation et vos objectifs",
      color: "border-green-600"
    },
    {
      icon: <Clock className="w-8 h-8 text-purple-600" />,
      title: "Réponse rapide",
      description: "Étude de votre dossier sous 24h et accompagnement réactif",
      color: "border-purple-600"
    },
    {
      icon: <Search className="w-8 h-8 text-orange-600" />,
      title: "Étude personnalisée",
      description: "Analyse complète de votre situation pour optimiser votre financement",
      color: "border-orange-600"
    },
    {
      icon: <FileCheck className="w-8 h-8 text-red-600" />,
      title: "Montage du dossier",
      description: "Prise en charge complète du montage de votre dossier de financement",
      color: "border-red-600"
    },
    {
      icon: <BadgeCheck className="w-8 h-8 text-teal-600" />,
      title: "Suivi jusqu'à la signature",
      description: "Accompagnement complet jusqu'à la signature chez le notaire",
      color: "border-teal-600"
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {features.map((feature, index) => (
        <FeatureItem
          key={index}
          icon={feature.icon}
          title={feature.title}
          description={feature.description}
          color={feature.color}
        />
      ))}
    </div>
  );
}