import React from 'react';
import { TrendingUp, Clock, Lock, BanknoteIcon } from 'lucide-react';

interface FeatureItemProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

function FeatureItem({ icon, title, description }: FeatureItemProps) {
  return (
    <div className="flex items-start space-x-4 bg-white/80 rounded-lg p-4 shadow-sm">
      <div className="flex-shrink-0">
        {icon}
      </div>
      <div>
        <h3 className="font-semibold text-gray-900">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
}

export function FeatureList() {
  const features = [
    {
      icon: <TrendingUp className="w-8 h-8 text-green-600" />,
      title: "Un rendement élevé",
      description: "Une rémunération attractive jusqu'à 7,35% par an"
    },
    {
      icon: <Clock className="w-8 h-8 text-green-600" />,
      title: "Votre épargne disponible",
      description: "Votre épargne disponible à tout moment"
    },
    {
      icon: <Lock className="w-8 h-8 text-green-600" />,
      title: "Offre 100% sécurisé",
      description: "Garantie du capital et accessibilité rapide aux fonds"
    },
    {
      icon: <BanknoteIcon className="w-8 h-8 text-green-600" />,
      title: "Aucun frais d'entrée",
      description: "Sans frais d'ouverture, de gestion ou de clôture"
    }
  ];

  return (
    <div className="space-y-4">
      {features.map((feature, index) => (
        <FeatureItem
          key={index}
          icon={feature.icon}
          title={feature.title}
          description={feature.description}
        />
      ))}
    </div>
  );
}