import React from 'react';
import { Link } from 'react-router-dom';

export function Footer() {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
        <div className="text-sm text-center flex flex-wrap items-center justify-center gap-4">
          <span>© {currentYear} IMHOFF STEPHANIE. Tous droits réservés.</span>
          <div className="flex gap-4">
            <Link 
              to="/mentions-legales"
              className="hover:text-white transition-colors"
            >
              Mentions Légales
            </Link>
            <Link 
              to="/confidentialite"
              className="hover:text-white transition-colors"
            >
              Politique de Confidentialité
            </Link>
            <Link 
              to="/conditions-generales"
              className="hover:text-white transition-colors"
            >
              CGU
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}