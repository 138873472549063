import React from 'react';
import { Star } from 'lucide-react';

export function StarRating() {
  return (
    <div className="flex flex-col items-center space-y-2">
      <div className="flex space-x-1">
        {[...Array(5)].map((_, i) => (
          <Star key={i} className="w-6 h-6 text-yellow-400 fill-current" />
        ))}
      </div>
      <div className="text-sm text-gray-600">
        <span className="font-semibold">4.7/5</span> - Avis Clients
      </div>
    </div>
  );
}