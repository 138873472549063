import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function CreditimoTypeformEmbed() {
  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://embed.typeform.com/next/embed.js';
    script.async = true;
    document.body.appendChild(script);

    window.addEventListener('message', (event) => {
      if (event.data.type === 'form-submit') {
        navigate('/creditimo/merci');
      }
    });

    return () => {
      document.body.removeChild(script);
      window.removeEventListener('message', () => {});
    };
  }, [navigate]);

  return (
    <div className="w-full h-[600px] md:h-[500px] relative">
      <div 
        data-tf-live="01JDHHMJHKJYW9EEQJZQVQJ96G"
        data-tf-iframe-props="title=Crédit Immobilier Form"
        data-tf-medium="snippet"
        data-tf-hidden="utm_source=website,utm_medium=creditimo_form"
        style={{ 
          width: '100%',
          height: '100%',
          border: 'none',
          borderRadius: '8px',
          overflow: 'hidden'
        }}
      />
    </div>
  );
}
