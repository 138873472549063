import React from 'react';
import { CreditimoFeatureList } from '../components/CreditimoFeatureList';
import { CreditimoTypeformEmbed } from '../components/CreditimoTypeformEmbed';
import { StarRating } from '../components/StarRating';
import { LegalNotice } from '../components/LegalNotice';
import { ArrowRight } from 'lucide-react';

export function CreditimoPage() {
  const benefits = [
    {
      title: "Taux avantageux",
      description: "Profitez des meilleures conditions du marché grâce à notre expertise et notre réseau de partenaires bancaires.",
      image: "https://images.unsplash.com/photo-1560520653-9e0e4c89eb11?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80"
    },
    {
      title: "Financement sur mesure",
      description: "Solutions adaptées à votre apport personnel et votre capacité d'emprunt.",
      image: "https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80"
    },
    {
      title: "Accompagnement complet",
      description: "De l'étude de votre dossier jusqu'à la signature chez le notaire, nous sommes à vos côtés.",
      image: "https://images.unsplash.com/photo-1560518883-ce09059eeffa?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 relative">
      {/* Hero Section */}
      <div className="relative h-[500px] overflow-hidden">
        <div 
          className="absolute inset-0"
          style={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1560518883-ce09059eeffa?ixlib=rb-4.0.3&auto=format&fit=crop&w=1742&q=80)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-black/50" />
        </div>
        
        <div className="relative z-10 max-w-7xl mx-auto px-4 h-full flex items-center">
          <div className="max-w-2xl text-white">
            <div className="w-16 h-16 bg-white shadow-lg rounded-lg flex items-center justify-center mb-8">
              <span className="text-3xl font-bold text-gray-900">SI</span>
            </div>
            
            <h1 className="text-5xl font-bold mb-6">
              CRÉDIT IMMOBILIER
            </h1>
            
            <p className="text-xl text-gray-200 mb-4">
              Financez votre projet immobilier aux meilleures conditions
            </p>
            
            <p className="text-lg text-green-400 mb-8">
              Solutions adaptées pour les emprunteurs avec apport personnel
            </p>
            
            <button 
              onClick={() => document.querySelector('#simulation')?.scrollIntoView({ behavior: 'smooth' })}
              className="bg-green-500 hover:bg-green-600 text-white px-8 py-4 rounded-lg font-semibold flex items-center gap-2 transition-colors"
            >
              Simuler mon crédit
              <ArrowRight className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>

      <div className="relative z-10 max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        {/* Main Content */}
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Optimisez votre financement immobilier
          </h2>
          <p className="text-xl text-gray-600">
            Que vous soyez primo-accédant ou investisseur, notre expertise vous permet d'obtenir 
            les meilleures conditions de financement en valorisant votre apport personnel.
          </p>
        </div>

        {/* Features Section */}
        <div className="py-16">
          <CreditimoFeatureList />
        </div>

        {/* Form Section */}
        <div id="simulation" className="bg-white rounded-2xl shadow-xl p-8 mb-24">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            <div className="space-y-6">
              <h2 className="text-3xl font-bold text-gray-900">Obtenez votre simulation personnalisée</h2>
              <p className="text-xl text-gray-600">
                Découvrez votre capacité d'emprunt et les meilleures offres de financement adaptées à votre projet
              </p>
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-center gap-2">
                  <span className="w-2 h-2 bg-green-500 rounded-full"></span>
                  Taux négociés auprès de nos partenaires bancaires
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-2 h-2 bg-green-500 rounded-full"></span>
                  Optimisation de votre plan de financement
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-2 h-2 bg-green-500 rounded-full"></span>
                  Accompagnement personnalisé jusqu'à la signature
                </li>
              </ul>
              <StarRating />
            </div>
            <div>
              <CreditimoTypeformEmbed />
            </div>
          </div>
        </div>

        {/* Benefits Section */}
        <div className="py-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">
            Nos engagements pour votre crédit immobilier
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden">
                <img 
                  src={benefit.image}
                  alt={benefit.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{benefit.title}</h3>
                  <p className="text-gray-600">{benefit.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="bg-gradient-to-r from-green-600 to-green-700 rounded-2xl p-12 text-center text-white mb-16">
          <h2 className="text-3xl font-bold mb-4">
            Prêt à concrétiser votre projet immobilier ?
          </h2>
          <p className="text-xl text-green-100 mb-8">
            Bénéficiez de l'expertise de nos conseillers pour optimiser votre financement
          </p>
          <button 
            onClick={() => document.querySelector('#simulation')?.scrollIntoView({ behavior: 'smooth' })}
            className="bg-white text-green-600 px-8 py-4 rounded-lg font-semibold hover:bg-green-50 transition-colors"
          >
            Démarrer ma simulation
          </button>
        </div>

        <div className="border-t pt-8">
          <LegalNotice />
        </div>
      </div>
    </div>
  );
}