import React from 'react';
import { FeatureCard } from '../components/FeatureCard';
import { FeatureList } from '../components/FeatureList';
import { StarRating } from '../components/StarRating';
import { LegalNotice } from '../components/LegalNotice';
import { TypeformEmbed } from '../components/TypeformEmbed';

export function HomePage() {
  const features = [
    {
      title: 'Sans frais d\'ouverture ou de clôture',
      description: 'Profitez pleinement de votre épargne sans aucun frais. Zéro ouverture, zéro gestion, zéro clôture !',
      imageSrc: 'https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80',
    },
    {
      title: 'Une rémunération attractive',
      description: 'Boostez vos économies avec un taux de 7,35% par an, un rendement inégalé sur le marché !',
      imageSrc: 'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80',
    },
    {
      title: 'Une épargne disponible à tout moment',
      description: 'Besoin d\'accéder à vos fonds ? Ils sont disponibles à tout moment, sans pénalités !',
      imageSrc: 'https://images.unsplash.com/photo-1633158829585-23ba8f7c8caf?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80',
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 relative">
      <div 
        className="absolute inset-0 z-0 opacity-5"
        style={{
          backgroundImage: 'url(https://images.unsplash.com/photo-1579621970563-ebec7560ff3e?ixlib=rb-4.0.3&auto=format&fit=crop&w=1742&q=80)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />

      <div className="relative z-10 max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          <div className="space-y-6">
            <div className="w-16 h-16 bg-white shadow-lg rounded-lg flex items-center justify-center">
              <span className="text-3xl font-bold">SI</span>
            </div>
            
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900">
              INVESTISSEZ DANS UN LIVRET À{' '}
              <span className="text-green-600">7,35% PAR AN !</span>
            </h1>

            <FeatureList />
          </div>

          <div className="bg-white rounded-2xl shadow-xl p-4 md:p-8">
            <div className="space-y-4">
              <div>
                <h2 className="text-xl md:text-2xl font-bold text-gray-900">Vérifiez votre éligibilité</h2>
                <p className="mt-2 text-gray-600">Et recevez votre étude personnalisée gratuitement</p>
              </div>
              
              <TypeformEmbed />

              <div className="pt-4 border-t">
                <StarRating />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-16 md:mt-24">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-8">
            Existe-t-il une alternative sécurisée aux livrets classiques ?
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
            <div className="space-y-4 md:space-y-6">
              <p className="text-base md:text-lg text-gray-700">
                Oui, notre livret est conçu pour les particuliers soucieux de maximiser leurs rendements tout en bénéficiant d'un placement sécurisé pour leurs économies.
              </p>
              <p className="text-base md:text-lg text-gray-700">
                Avec un taux de rémunération pouvant atteindre jusqu'à 7,35% par an, une garantie de capital, une disponibilité des fonds immédiate, un taux fixe et une durée de placement flexible, notre livret offre la combinaison parfaite de rentabilité et de sécurité. Rejoignez-nous dès aujourd'hui pour assurer la croissance de votre patrimoine financier !
              </p>
            </div>
            <div className="flex justify-center">
              <img 
                src="https://images.unsplash.com/photo-1579621970563-ebec7560ff3e?ixlib=rb-4.0.3&auto=format&fit=crop&w=1742&q=80" 
                alt="Investment Growth"
                className="rounded-lg shadow-lg max-w-full md:max-w-md"
              />
            </div>
          </div>
        </div>

        <div className="mt-16 md:mt-24">
          <h2 className="text-2xl md:text-3xl font-bold text-center text-gray-900 mb-8 md:mb-12">
            Investissez dans un livret à haut rendement et sécurisé dès aujourd'hui !
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
            {features.map((feature, index) => (
              <FeatureCard
                key={index}
                title={feature.title}
                description={feature.description}
                imageSrc={feature.imageSrc}
              />
            ))}
          </div>
        </div>

        <div className="mt-12 md:mt-16 border-t pt-8">
          <LegalNotice />
        </div>
      </div>
    </div>
  );
}
